import { useState } from 'react'
import Navbar from '../components/Navbar'
import Slider from '../components/Slider'
import appleIcon from '../assets/images/apple-transparent-icon.png'
import playstoreIcon from '../assets/images/android-transparent-icon.png'
import androidVideo from '../assets/videos/v1.mp4'
import iosVideo from '../assets/videos/v2.mp4'


function Home() {
  const [activeTab, setActiveTab] = useState('android');
  const [videoSource, setVideoSource] = useState(androidVideo)

  const handler = (e) => {
    setActiveTab(activeTab === 'android' ? 'ios' : 'android')
    setVideoSource(videoSource === androidVideo ? iosVideo : androidVideo)
  }

  return (
    <div className='' >
      <Navbar />
      <section className='flex flex-col items-center h-screen px-5' id='section-1'>
        <h1 class="text-center lg:text-6xl mt-36 mb-12 text-white"
        >
          <span className='text-center lg:text-6xl text-2xl text-[#64E3FF]'>ASYABAHİS’le</span> Her Anın <br />Heyecan Dolu!
        </h1>
        <p className='text-center lg:text-2xl text-sm mb-12 text-white'>
          AsyaBahis’in sınırsız bahis keyfi ile istediğin yerde, istediğin zaman hayatına heyecan getir.<br /> Uygulamayı cihazına yükle dilediğin zaman bahis keyfinin tadını çıkar.
        </p>
        <div>
          <a href='#appstore-slider' type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-3 py-1 text-left inline-flex items-center dark:focus:ring-[#050708]/50 dark:hover:bg-[#050708]/30 mr-2 mb-2">
            <svg class="w-5 h-5 mr-2 -ml-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path></svg>
            App Store’dan <br />İndir
          </a>
          <a href='#google-slider' type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-3 py-1 text-left inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
            <svg class="w-4 h-4 mr-2 -ml-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
            Google Play’den <br />İndir
          </a>
        </div>
      </section>
      <section className='flex flex-col items-center justify-center' id='section-2'>
        <div className='relative -mt-[12%]' >
          <img src={require('../assets/images/section-2-img-1.png')} className='' alt='' />
        </div>
        <div className='mb-24' >
          <h2 className='lg:text-7xl text-center text-[#64E3FF]' >ASYABAHİS’İ İNDİR</h2>
          <p className='lg:text-2xl mt-5 text-center text-white' >Görselleri takip edin ve AsyaBahis’i istediğiniz platforma<br /> kolayca yökleyin.</p>
        </div>
      </section>
      <section className='lg:pb-24 pb-10 relative overflow-hidden relative' id='google-slider'>
        <div className='blur-ball-left' />
        <div className='flex my-10' >
          <img className='m-auto max-w-[40px]' src={require('../assets/images/android-transparent-icon.png')} />
        </div>
        <div className='mb-10 px-5' >
          <h3 className='text-4xl text-center font-semibold text-white' >AsyaBahis Nasıl İndirilir?<br /><span className='text-[#898989]' >(Android)</span> </h3>
        </div>
        <div className='lg:px-36 px-5 ' >
          <Slider platform='android' />
        </div>
      </section>
      <section className='lg:pb-24 pb-10 overflow-hidden relative' id='appstore-slider'>
        <div className='blur-ball-right' />
        <div className='flex mb-10' >
          <img className='m-auto max-w-[40px]' src={require('../assets/images/apple-transparent-icon.png')} />
        </div>
        <div className='mb-10 px-5' >
          <h3 className='text-4xl text-center font-semibold text-white' >AsyaBahis Nasıl İndirilir?<br /><span className='text-[#898989]' >(IOS)</span> </h3>
        </div>
        <div className='lg:px-36 px-5' >
          <Slider platform='ios' />
        </div>
      </section>
      <section className='bg-color-dark flex flex-col mb-24 pb-10' >
       
      <div className='m-auto flex gap-5 mb-12 p-1 rounded-3xl border-2' style={{ borderColor: 'rgba(255, 255, 255, 0.07)' }}>
            <div
              className={`flex justify-center p-3 rounded-2xl ${activeTab === 'android' ? 'bg-[#363636]' : ''}`}
              onClick={handler}>
             
             <button type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-3 py-1 text-center mr-2 flex flex-row items-center justify-center ">    
            <img className='m-auto max-w-[40px] mr-3' src={require('../assets/images/apple-transparent-icon.png')} />
            <span className='text-center'>APP STORE</span>
             </button>
            
            </div>
            <div
              className={`flex justify-center p-3 rounded-2xl ${activeTab === 'ios' ? 'bg-[#363636]' : ''}`}
              onClick={handler} >
           
            <button type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-3 py-1 text-center mr-2 flex flex-row items-center justify-center ">    
            <img className='m-auto max-w-[40px] mr-3' src={require('../assets/images/android-transparent-icon.png')} />
            <span className='text-center'>PLAY STORE</span>
          </button>
          
            </div>
          </div>
          <div className='mb-6 px-5' >
            <Player source={videoSource} key={activeTab}/>
          </div>
   
      </section>
      <section className='lg:px-24' >
        <h2 className='lg:text-7xl text-center text-[#64E3FF] mb-10' >HEMEN BAŞLA!</h2>
        <div className='flex lg:flex-row flex-col justify-center '>
          <div className='p-10 lg:basis-1/2'>
            <p className='text-white text-xl' >
              AsyaBahis’in herkes için kullanışlı arayüzü ve binlerce çeşit oyunları seni bekliyor. Spor müsabakalarından sanal oyunlara, canlı bahisten, casino oyunlarına kadar binlerce müsabaka ve oyun AsyaBahiste!
              <br /><br />
              AsyaBahis’i hemen indir, bahisin ve oyunların heyecanını istediğin yerden yaşa!
            </p>
          </div>
          <div className='lg:basis-1/2' >
            <div>
              <img src={require('../assets/images/section-6-img-1.png')} alt='sekabet banner video' className='m-auto' />
            </div>
          </div>
        </div>
      </section>
      <footer className='lg:px-24 pb-12' >
        <div className='max-w-100 px-10' >
          <div className='flex flex-row gap-5 mb-5'>
            <a href='/' >
              <img src={require('../assets/images/twitter.png')} />
            </a>
            <a href='/' >
              <img src={require('../assets/images/facebook.png')} />
            </a>
            <a href='/' >
              <img src={require('../assets/images/instagram.png')} />
            </a>
            <a href='/' >
              <img src={require('../assets/images/telegram.png')} />
            </a>
          </div>
          <div className=''>
            <p className='text-white' >© 2023 ASYABAHIS</p>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Home

const Player = ({key,source}) => {
  return (
    <video key={key} class="w-3/4 border m-auto border-gray-200 rounded-lg dark:border-gray-700" controls>
      <source src={source} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
}
